@font-face {
  font-family: 'Aeonik';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Aeonik-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 700;
  font-style: italic;
  src: url('../assets/fonts/Aeonik-BoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Aeonik-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Aeonik-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 400;
  font-style: italic;
  src: url('../assets/fonts/Aeonik-RegularItalic.otf') format('opentype');
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/Aeonik-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 300;
  font-style: italic;
  src: url('../assets/fonts/Aeonik-LightItalic.otf') format('opentype');
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Aeonik';
  font-weight: 400;
}

html {
  font-family: 'Aeonik';
  font-weight: 400;
  overflow-x: hidden;
  --color-dark-blue: rgb(9,12,22);
  --color-modal-blue: #111524;
  --color-modal-light-blue: #1D2237;
  --color-blue-accent: #3294F8;
  --color-blue: #3294F8;
  --color-blue-light: #2E3551;
  --color-dark-gray: #3B4568;
  --color-white: rgba(255,255,255,1);
  --color-gray: #777F99;
  --color-dark: #7F7D97;
  --color-button-bg-dark: #181D2F;
}

body {
  position: relative;
  min-height: 100vh;
  background: #090C16;
  overflow-x: hidden;
}

button {
  padding: 0;
  cursor: pointer;
}

button,
textarea,
input {
  font-family: 'Aeonik';
}

.track-vertical {
  right: 2px;
  display: block !important;
  height: 100%;
  background: #101833;
  border-radius: 8px;
}

.thumb-vertical {
  right: 0;
  display: block !important;
  background: #00C0FF;
  border-radius: 8px;
}


.altWidget {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #545368;
}


::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1D2237;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3B4568;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3294F8;
}







:root {

  --onboard-white: 'black';
  --onboard-wallet-app-icon-background: 'black';
  /* CUSTOMIZE ACCOUNT CENTER*/
  --account-center-z-index: 10;

  /* MAIN MODAL POSITIONING */
  --onboard-modal-z-index: 10;

  /* HD WALLET ACCOUNT SELECT MODAL POSITIONING */
  --onboard-account-select-modal-z-index: 10;


  /* MAGIC WALLET MODAL POSITIONING */
  --onboard-login-modal-z-index: 10;

  --notify-onboard-z-index: 10;
}


@media screen and (max-width: 500px) {
  :root {
    --account-center-position-bottom: 20px;
    --account-center-position-top: 90%;
  }
}


.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}